<template>
  <v-container class="general">
    <PageTitle :component="'ReportListPage'" :group-plugin-id="groupPluginId">
      <template slot="actions">
        <VideoTutorial
          :component="'ReportListPage'"
          :title="$t('reportsTutorialTitle')"
        />
      </template>
    </PageTitle>
    <div v-if="!loading" class="mb-5">
      <v-layout class="row wrap">
        <v-flex class="xs12">
          <v-text-field
            v-model="model.name"
            v-validate.disable="'required'"
            :error-messages="errors.collect('name')"
            :data-vv-name="'name'"
            :data-vv-as="$tc('name', 2)"
            :label="$tc('name', 1)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-radio-group v-model="selectedColumnOption">
            <v-radio
              v-for="(column, i) in columnOptions"
              :key="`column-${i}`"
              :label="column.name"
              :value="column.id"
            ></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex xs12 class="mb-4">
          <!-- <div class="pl-4 pr-4 pt-4 pb-3">
            <strong>{{ $t('report-parameters') }}</strong>
            <p>{{ $t('report-layout-instructions') }}</p>
          </div>-->
          <draggable
            class="dragArea"
            v-model="parameters"
            :options="{ handle: '.handle' }"
          >
            <div
              v-for="(attribute, i) in parameters"
              :key="`attribute-${i}`"
              class="drag-element"
            >
              <v-layout
                v-if="attribute.flag != 'delete'"
                class="row nowrap align-center"
              >
                <span class="handle pr-3">
                  <v-btn icon>
                    <font-awesome-icon
                      icon="arrows-alt"
                      color="rgba(0,0,0,0.3)"
                    />
                  </v-btn>
                </span>
                <v-flex class="xs12">
                  <v-autocomplete
                    v-model="attribute.attribute_id"
                    :items="availableAttributes(attribute.attribute_id)"
                    :error-messages="errors.collect('parameter_' + i)"
                    v-validate.disable="'required'"
                    :data-vv-name="'parameter_' + i"
                    :data-vv-as="$tc('column', 2)"
                    item-text="name"
                    item-value="id"
                    auto-select-first
                    persistent-hint
                    :label="$tc('column', 1)"
                  >
                    <template v-slot:append-outer>
                      <v-slide-x-reverse-transition
                        mode="out-in"
                      ></v-slide-x-reverse-transition>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <span>
                  <v-btn icon @click="removeParam(attribute, i)">
                    <font-awesome-icon class="sw-accent" icon="trash" />
                  </v-btn>
                </span>
              </v-layout>
            </div>
          </draggable>
        </v-flex>
        <v-flex xs12 offset-xs1 v-if="exceededMaxAllowedParameters && allowSaveReport" class="accent-text limit-error-text">{{ $t("report_page.question-limit", {
            limit: maxAllowedParameters, type: 'report'
            })
          }}
        </v-flex>
        <v-flex v-if="allowAddParameters" xs12>
          <v-btn
            :disabled="exceededMaxAllowedParameters"
            round
            class="sw-secondary-bg sw-on-secondary text-none"
            @click="addParam()">
            {{ $t("add-column") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <v-layout class="row wrap">
      <v-flex v-if="!allowSaveReport" class="xs12 pa-4 text-center error--text"
      >For numbers you are limited to two columns
      </v-flex
      >
      <v-flex xs12>
        <v-btn
          round
          large
          class="ml-0 mr-3 white sw-accent text-none"
          @click="$router.push({ name: 'reports' })"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          v-if="!groupReportId && allowSaveReport"
          round
          large
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          @click="createGroupReport"
          >{{ $t("create") }}</v-btn
        >
        <v-btn
          v-if="groupReportId && allowSaveReport"
          round
          large
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          @click="updateGroupReport"
          >{{ $t("save") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  data: (vm) => ({
    component: "ReportListPage",
    loading: false,
    model: {
      icon: "fas list-alt",
    },
    userProperties: {},
    columnOptions: [
      {
        id: 1,
        name: vm.$t("show-numbers"),
      },
      {
        id: 2,
        name: vm.$t("show-guests-and-their-data"),
      },
    ],
    selectedColumnOption: 1,
    parameters: [],
    attributes: [],
    tempId: 1,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    groupReportId() {
      return this.$route.params.report_id;
    },

    maxAllowedParameters() {
      return  parseInt(process.env.VUE_APP_REPORT_COLUMN_LIMIT + "") || 20;
    },

    exceededMaxAllowedParameters() {
      const parameters = this.parameters.filter(
          (param) => param.flag !== "delete",
        );
      return parameters.length >= this.maxAllowedParameters;
    },

    allowAddParameters() {
      const parameters = this.availableParameters.filter(
        (param) => param.flag !== "delete",
      );
      if (this.selectedColumnOption === 2) {
        return true;
      } else if (this.selectedColumnOption === 1 && parameters.length < 2) {
        return true;
      }
      return false;
    },
    allowSaveReport() {
      const parameters = this.availableParameters.filter(
        (param) => param.flag !== "delete",
      );
      if (this.selectedColumnOption === 2) {
        return true;
      } else if (this.selectedColumnOption === 1 && parameters.length < 3) {
        return true;
      }
      return false;
    },
    availableParameters() {
      let properties = {};
      if (this.userProperties && this.userProperties.id) {
        if (this.selectedColumnOption === 2) {
          properties = {
            id: this.userProperties.id,
            attribute_id: this.userProperties.attribute_id,
            flag: "update",
          };
        } else {
          properties = {
            id: this.userProperties.id,
            attribute_id: this.userProperties.attribute_id,
            flag: "delete",
          };
        }
      } else {
        if (this.selectedColumnOption === 2) {
          properties = {
            id: this.generateId(),
            attribute_id: null,
            flag: "create",
          };
        }
      }
      if (properties.id) {
        return [...[properties], ...this.parameters];
      }
      return this.parameters;
    },
    isTutorialsEnabled() {
      return process.env.VUE_APP_TUTORIALS_ENABLED == "true";
    },
  },
  components: {
    draggable,
  },
  mounted() {
    this.listGroupUserAttributes();
    this.listGroupReportParams();
    this.getReport();
  },
  methods: {
    generateId() {
      return "temp_" + this.tempId++;
    },
    availableAttributes(attribute_id) {
      const selected = this.parameters.filter((param) => {
        if (param.flag == "create" || param.flag == "update") return true;
      });
      return this.attributes.filter((attribute) => {
        if (attribute.id === attribute_id) return true;
        for (const param of selected) {
          if (attribute.id === param.attribute_id) {
            return false;
          }
        }
        return true;
      });
    },
    addParam() {
      this.parameters.push({
        id: this.generateId(),
        attribute_id: null,
        flag: "create",
      });
    },
    transformParametersForEdit(parameters) {
      return parameters.map((param) => {
        return {
          id: param.id,
          attribute_id: param.attribute_id,
          flag: "update",
        };
      });
    },
    listGroupUserAttributes() {
      const params = [
        this.groupId,
        {
          per_page: 100,
        },
      ];

      this.$api.groupUserAttributes.list(...params).then(
        (response) => {
          this.attributes = response.data.data.filter((el) => el.enabled);
        },
        () => {},
      );
    },
    getReport() {
      if (!this.groupReportId) return;

      this.loading = true;

      const params = [this.groupId, this.groupReportId];

      this.$api.groupReports.get(...params).then(
        (response) => {
          this.loading = false;
          this.model = response.data.data;
        },
        () => {
          this.loading = false;
        },
      );
    },
    listGroupReportParams() {
      if (!this.groupReportId) return;

      const params = [
        this.groupId,
        this.groupReportId,
        {
          per_page: 100,
        },
      ];

      this.$api.groupReportParams.list(...params).then(
        (response) => {
          const reportParams = response.data.data.filter(
            (param) => param.id && param.attribute_id,
          );

          if (reportParams[0]) {
            this.parameters = this.transformParametersForEdit(reportParams);
          }

          this.userProperties = response.data.data.find(
            (param) => param.id && !param.attribute_id,
          );

          if (this.userProperties && this.userProperties.id) {
            this.selectedColumnOption = 2;
          }
        },
        () => {},
      );
    },
    createGroupReportParams(reportId, attributeId, sortOrder) {
      if (!reportId || sortOrder === null) {
        return;
      }

      const specs = [
        this.groupId,
        reportId,
        {
          attribute_id: attributeId,
          sort_order: sortOrder,
        },
      ];

      this.$api.groupReportParams.create(...specs).catch();
    },
    updateGroupReportParams(paramId, attributeId, sortOrder) {
      if (!paramId || sortOrder === null) {
        return;
      }

      const specs = [
        this.groupId,
        this.groupReportId,
        paramId,
        {
          attribute_id: attributeId,
          sort_order: sortOrder,
        },
      ];

      this.$api.groupReportParams.update(...specs);
    },
    removeParam(attribute, index) {
      if (attribute.flag == "delete") {
        this.parameters[index].flag = "update";
      } else if (attribute.flag == "create") {
        this.parameters.splice(index, 1);
      } else {
        this.parameters[index].flag = "delete";
      }
    },
    async createGroupReport() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      const specs = [
        this.groupId,
        {
          name: this.model.name,
          icon: this.model.icon,
        },
      ];

      const report = await this.$api.groupReports
        .create(...specs)
        .catch((error) => this.errorMessageShow(error));

      if (!report) return;

      for (const [i, param] of this.availableParameters.entries()) {
        this.createGroupReportParams(
          report.data.data.id,
          param.attribute_id,
          i,
        );
      }

      this.$store.dispatch("addNotification", {
        message: this.$t("report-created"),
      });

      this.$router
        .push({
          name: "reports",
        })
        .catch(() => {});
    },
    async updateGroupReport() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      const specs = [
        this.groupId,
        this.groupReportId,
        {
          name: this.model.name,
          icon: this.model.icon,
        },
      ];

      const report = await this.$api.groupReports
        .update(...specs)
        .catch((error) => this.errorMessageShow(error));

      if (!report) return;

      const paramsToDelete = this.availableParameters.filter(
        (el) => el.flag === "delete",
      );

      for (const param of paramsToDelete) {
        const params = [this.groupId, this.groupReportId, param.id];

        this.$api.groupReportParams.delete(...params).catch(() => {});
      }

      for (const [i, param] of this.availableParameters.entries()) {
        if (param.flag === "create") {
          this.createGroupReportParams(
            this.groupReportId,
            param.attribute_id,
            i,
          );
        }

        if (param.flag === "update") {
          this.updateGroupReportParams(param.id, param.attribute_id, i);
        }
      }

      this.$store.dispatch("addNotification", {
        message: this.$t("report-updated"),
      });

      this.$router
        .push({
          name: "reports",
        })
        .catch(() => {});
    },
    async deleteGroupReport() {
      if (!this.model || !this.model.id) {
        return;
      }

      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("report-deletion-warning"),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!isConfirmed) return;

      const params = [this.groupId, this.model.id];

      this.$api.groupReports.delete(...params).then(
        (response) => {
          this.$store.dispatch("addNotification", {
            message: this.$t("report-deleted", {
              name: this.model.name,
            }),
          });

          this.$router
            .push({
              name: "reports",
              params: {
                group_id: this.groupId,
                group_plugin_id: this.groupPluginId,
              },
            })
            .catch(() => {});
        },
        () => this.errorMessageShow(error),
      );
    },
  },
};
</script>

<style scoped>
/* SPECIFIC STYLING */

.handle {
  cursor: pointer;
}

.sortable-chosen {
  opacity: 0.7;
  background-color: #dcdcdc;
}

.sortable-ghost {
  background-color: #dcdcdc;
}

.mainheader /deep/ .v-text-field__details {
  display: none;
}

.mainheader .report-name /deep/ input {
  font-weight: bold;
}

.mainheader .report-desc /deep/ input {
  font-size: 12px;
}

.mainheader /deep/ .v-input__slot {
  padding-left: 0;
}

.drag-element /deep/ .v-input__slot {
  margin: 12px 0;
}

.drag-element /deep/ .v-text-field__details {
  margin: 0;
  padding: 0;
}

.drag-element /deep/ .v-messages {
  min-height: 0;
}
.limit-error-text{
  margin-left: 1%;
  margin-bottom: 20px;
}
</style>
